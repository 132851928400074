import axiosInstance from "../utils/axios";
import { sendPasswordResetEmail } from "./firebase";
import {
  Participant,
  ParticipantLimits,
  ParticipantRegistrationData,
  ParticipantUser,
  ParticipantUserRegistrationData,
} from "../types/entities/participants";

export async function getParticipants() {
  const { data } = await axiosInstance.get<Array<Record<string, any>>>(
    "/participants"
  );

  const participants: Participant[] = data.map((item) => ({
    id: item.id,
    tradeName: item.companyTradeName,
    cnpj: item.companyCnpj,
    classId: item.classes.id,
    email: item.companyEmail,
    registrationStatus: item.registrationStatus,
    active: item.active,
  }));
  return participants;
}

export async function getParticipant(id: string | undefined) {
  if (!id) return null;

  const { data } = await axiosInstance.get(`/participants/${id}`);

  const participant: ParticipantRegistrationData = {
    classId: data.classes.id,
    corporateName: data.companyCorporateName,
    tradeName: data.companyTradeName,
    cnpj: data.companyCnpj,
    stateRegistration: data.companyStateRegistration,
    municipalRegistration: data.companyMunicipalRegistration,
    site: data.companyWebSite,
    email: data.companyEmail,
    companyPhone: data.companyPhone,
    companyCell: data.companyCellphone,

    registrationStepsFinished: data.registrationStepsFinished,

    cep: data.participantAddresses.addressCep,
    addressLine: data.participantAddresses.addressLine,
    addressNumber: data.participantAddresses.addressNumber,
    complement: data.participantAddresses.addressComplement,
    district: data.participantAddresses.addressDistrict,
    city: data.participantAddresses.addressCity,
    state: data.participantAddresses.addressState,
    registrationStatus: data.registrationStatus,
    active: data.active,

    bankCode: (data.participantBankData || {}).bankCode || "",
    bankAccount: (data.participantBankData || {}).bankAccount || "",
    bankAgency: (data.participantBankData || {}).bankAgency || "",
  };

  return participant;
}

export async function createParticipant(
  registrationData: ParticipantRegistrationData
) {
  const dto = {
    companyClassId: registrationData.classId,
    companyCorporateName: registrationData.corporateName,
    companyTradeName: registrationData.tradeName,
    companyCnpj: registrationData.cnpj,
    companyStateRegistration: registrationData.stateRegistration,
    companyMunicipalRegistration: registrationData.municipalRegistration,
    companyWebSite: registrationData.site,
    companyEmail: registrationData.email,
    companyPhone: registrationData.companyPhone,
    companyCell: registrationData.companyCell,
    addressCep: registrationData.cep,
    addressLine: registrationData.addressLine,
    addressNumber: registrationData.addressNumber,
    addressComplement: registrationData.complement,
    addressDistrict: registrationData.district,
    addressCity: registrationData.city,
    addressState: registrationData.state,
    bankCode: registrationData.bankCode,
    bankAgency: registrationData.bankAgency,
    bankAccount: registrationData.bankAccount,
  };
  const { data } = await axiosInstance.post("/participants", dto);

  return data;
}

export async function editParticipant(
  data: ParticipantRegistrationData,
  id: string
) {
  const dto = {
    companyClassId: data.classId,
    companyCorporateName: data.corporateName,
    companyTradeName: data.tradeName,
    companyCnpj: data.cnpj,
    companyStateRegistration: data.stateRegistration,
    companyMunicipalRegistration: data.municipalRegistration,
    companyWebSite: data.site,
    companyEmail: data.email,
    companyPhone: data.companyPhone,
    companyCell: data.companyCell,

    addressCep: data.cep,
    addressLine: data.addressLine,
    addressNumber: data.addressNumber,
    addressComplement: data.complement,
    addressDistrict: data.district,
    addressCity: data.city,
    addressState: data.state,

    bankCode: data.bankCode,
    bankAgency: data.bankAgency,
    bankAccount: data.bankAccount,
  };
  await axiosInstance.patch(`/participants/${id}`, dto);

  return;
}

export async function getParticipantClasses() {
  const { data } = await axiosInstance.get<
    Array<{ id: number; name: string; value: string }>
  >("/participants/classes");

  const classes = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });

  return classes;
}

export async function getParticipantUsers(participantId: string | number) {
  const { data } = await axiosInstance.get<Array<any>>(
    `/participant-users/${participantId}`
  );

  const participantUsers = data.map((item) => {
    return {
      id: item.user.id,
      fullName: item.user.fullName,
      email: item.user.email,
      cpf: item.user.cpf,
      roleId: item.roleBalcao?.id,
      active: item.active,
    };
  });

  return participantUsers;
}

export async function getParticipantUser(
  participantId: string,
  userId: string
) {
  if (!userId) return null;

  const { data: userData } = await axiosInstance.get<any>(
    `/participant-users/find-one/${userId}/${participantId}`
  );

  const participantUser: ParticipantUserRegistrationData = {
    fullName: userData.fullName,
    email: userData.email,
    alwaysSign: userData.alwaysSign,
    position: userData.jobRole,
    telephoneNumber: userData.phoneNumber,
    cellphoneNumber: userData.cellphoneNumber,
    cpf: userData.cpf,
    nationality: userData.citizenship,
    birthDate: userData.birthDate,

    // allowedSystems: userData.allowedSystems,
    allowedSystems: 1,
    roleId: userData.roleBalcao?.id,

    documentTypeId: userData.userDocuments.documentTypeId,
    documentNumber: userData.userDocuments.documentNumber,
    socialNetwork: userData.socialNetwork,
    dateOfIssue: userData.userDocuments.documentIssueDate.slice(0, 10),
    issuingAgency: userData.userDocuments.documentDispatchingEntity,
    federatedUnit: userData.userDocuments.documentUf,
  };

  return participantUser;
}

export async function createParticipantUser(
  participantId: string,
  registrationData: ParticipantUserRegistrationData
) {
  const dto = {
    fullName: registrationData.fullName,
    email: registrationData.email,
    alwaysSign: registrationData.alwaysSign,
    jobRole: registrationData.position,
    phoneNumber: registrationData.telephoneNumber,
    cellphoneNumber: registrationData.cellphoneNumber,
    cpf: registrationData.cpf,
    citizenship: registrationData.nationality,
    birthDate: registrationData.birthDate,

    // allowedSystems: registrationData.allowedSystems,
    allowedSystems: ["TRADING_PLATFORM"],
    roleId: registrationData.roleId,
    socialNetwork: registrationData.socialNetwork,

    documentTypeId: registrationData.documentTypeId,
    documentNumber: registrationData.documentNumber,
    documentIssueDate: registrationData.dateOfIssue,
    documentDispatchingEntity: registrationData.issuingAgency,
    documentUf: registrationData.federatedUnit,
  };
  const { data } = await axiosInstance.post(
    `/participant-users/${participantId}`,
    dto
  );

  return data;
}

export async function editParticipantUser(
  participantId: string,
  userId: string,
  registrationData: ParticipantUserRegistrationData
) {
  const dto = {
    fullName: registrationData.fullName,
    email: registrationData.email,
    alwaysSign: registrationData.alwaysSign,
    jobRole: registrationData.position,
    phoneNumber: registrationData.telephoneNumber,
    cellphoneNumber: registrationData.cellphoneNumber,
    cpf: registrationData.cpf,
    citizenship: registrationData.nationality,
    birthDate: registrationData.birthDate,

    // allowedSystems: registrationData.allowedSystems,
    allowedSystems: ["TRADING_PLATFORM"], // TODO; use enum
    roleId: registrationData.roleId,

    socialNetwork: registrationData.socialNetwork,
    documentTypeId: registrationData.documentTypeId,
    documentNumber: registrationData.documentNumber,
    documentIssueDate: registrationData.dateOfIssue,
    documentDispatchingEntity: registrationData.issuingAgency,
    documentUf: registrationData.federatedUnit,
  };
  const { data } = await axiosInstance.patch(
    `/participant-users/update/${userId}/${participantId}`,
    dto
  );

  return data;
}

export async function getParticipantUserDocumentTypes() {
  const { data } = await axiosInstance.get<
    Array<{ id: number; name: string; value: string }>
  >("/participant-users/user-documents");

  const documentTypes = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });

  return documentTypes;
}

export async function getParticipantUserSystems() {
  // const { data } = await axiosInstance.get<
  //   Array<{ id: number; name: string; value: string }>
  // >("/participant-users/systems");

  // const systems = data.map((item) => {
  //   return {
  //     id: item.id,
  //     name: item.name,
  //     value: item.value,
  //   };
  // });

  const systems = [
    {
      id: 1,
      name: "Balcão de Compra e Venda de Energia",
      value: "Balcão de Compra e Venda de Energia",
    },
  ];

  return systems;
}

export async function getParticipantUserRoles() {
  const { data } = await axiosInstance.get<
    Array<{ id: number; name: string; value: string }>
  >("/participant-users/roles");

  const roles = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });

  return roles;
}

export async function deactivateParticipant({
  participantId,
}: {
  participantId: string | number;
}) {
  const { data } = await axiosInstance.patch(
    `/participants/inactive/${participantId}`
  );
  return data;
}

export async function activateParticipant({
  participantId,
}: {
  participantId: string | number;
}) {
  const { data } = await axiosInstance.patch(
    `/participants/active/${participantId}`
  );

  // const participantUsers = await getParticipantUsers(participantId);

  // participantUsers.forEach((participantUser) => {
  //   sendPasswordResetEmail(participantUser.email);
  // });

  return data;
}

export async function getUserByEmail(email: string) {
  if (email === "") return null;
  const { data } = await axiosInstance.get(`/participant-users/email/${email}`);
  return data;
}

export async function setParticipantLimits(
  participantId: string,
  participantLimitsDate: ParticipantLimits
) {
  const participantLimis = {
    totalOperatingLimit: participantLimitsDate.totalOperatingLimit,
    tradingOperatingLimit: participantLimitsDate.tradingOperatingLimit,
  };

  const usersLimits = participantLimitsDate.userLimits.map((user) => ({
    id: user.userId,
    totalOperatingLimit: user.totalOperatingLimit,
  }));

  await axiosInstance.put(
    `/participants/${participantId}/limits`,
    participantLimis
  );

  await axiosInstance.put(
    `/participant-users/${participantId}/limits`,
    usersLimits
  );
}

export async function getParticipantLimits(participantId: string | number) {
  const { data } = await axiosInstance.get<any>(
    `/participants/${participantId}/limits`
  );

  const participantLimits = {
    totalOperatingLimit: data.totalOperatingLimit || 0,
    tradingOperatingLimit: data.tradingOperatingLimit || 0,
  };

  return participantLimits;
}

export async function getParticipantUserLimits(participantId: string | number) {
  const { data } = await axiosInstance.get<{ users: Array<any> }>(
    `/participant-users/${participantId}/limit`
  );

  const { data: consumed } = await axiosInstance.get<Array<any>>(
    `${process.env.REACT_APP_API_NEGOTIATION_URL}/operation/performed/participant/${participantId}`
  );

  return data.users.map((user) => {
    return {
      id: user.id,
      userId: user.userId,
      cpf: user.cpf,
      email: user.email,
      name: user.fullName,
      totalOperatingLimit: user.totalOperatingLimit || 0,
      operatingLimitConsumed:
        consumed.find((consumed) => consumed.userId === user.id)?.amountUsed ||
        0,
    };
  });
}
