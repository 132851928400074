import axiosInstance from "../utils/axios";
import { ParticipantDocumentsDto } from "../types/dtos/participant-documents.dto";

export async function createParticipantDocuments(
  participantId: number | string,
  participantDocumentsDto: ParticipantDocumentsDto
) {
  await axiosInstance.post(
    `/participant-documents/${participantId}`,
    participantDocumentsDto
  );
}

export async function getAllParticipantdocuments(
  participantId: number | string
) {
  const { data } = await axiosInstance.get(
    `/participant-documents/${participantId}`
  );
  return data;
}

export async function updateParticipantDocuments(
  participantId: number | string,
  participantDocumentsDto: ParticipantDocumentsDto
) {
  await axiosInstance.patch(
    `/participant-documents/${participantId}`,
    participantDocumentsDto
  );
}
