import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { formatCNPJ } from "../../utils/string";
import {
  activateParticipant,
  deactivateParticipant,
  getParticipantClasses,
  getParticipants,
} from "../../services/participants";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { ApiError } from "../../types/error";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const SwitchBox = styled(Box)`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 58px;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  vertical-align: middle;
`;

const TradeNameCell = styled.div`
  display: flex;
  align-items: center;
`;

function ParticipantTable() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    data: participantsData,
    isLoading,
    isError,
  } = useQuery(["/participants"], getParticipants);

  const { data: classNames } = useQuery(
    ["/participants/classes"],
    getParticipantClasses,
    {
      initialData: [],
    }
  );

  const toggleParticipantActive = useMutation<
    any,
    unknown,
    { participantId: number }
  >(activateParticipant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants"]);
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const toggleParticipantInactive = useMutation<
    any,
    unknown,
    { participantId: number }
  >(deactivateParticipant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants"]);
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const handleToggleParticipantActive = React.useCallback(
    (participantId: number, value: boolean) => {
      if (value) {
        toggleParticipantActive.mutate({ participantId });
      } else {
        toggleParticipantInactive.mutate({ participantId });
      }
    },
    [toggleParticipantActive, toggleParticipantInactive]
  );

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      { id: "tradeName", alignment: "left", label: t("Trade Name") },
      { id: "cnpj", alignment: "left", label: t("CNPJ") },
      { id: "className", alignment: "left", label: t("Class") },
      { id: "email", alignment: "left", label: t("Email") },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || participantsData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <EnhancedTable
      title={t("Participants")}
      data={participantsData}
      headCells={headCells}
      keyExtractor={(row) => String(row.id)}
      renderRow={(row) => {
        const isDraft = row.registrationStatus === "Rascunho";

        return (
          <TableRow hover tabIndex={-1}>
            <TableCell component="th" scope="row">
              <TradeNameCell>
                <Box mr={1}>{row.tradeName}</Box>
                {isDraft && <Chip size="small" label={t("Draft")} />}
              </TradeNameCell>
            </TableCell>
            <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
              {formatCNPJ(row.cnpj) ?? "-"}
            </TableCell>
            <TableCell align="left">
              {classNames.find((item) => item.id === row.classId)?.name ??
                row.classId}
            </TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell padding="none" align="right">
              <Box mr={2} my={2} whiteSpace="nowrap">
                <Tooltip title={t("Edit")}>
                  <IconButton
                    aria-label={t("Edit")}
                    component={NavLink}
                    to={`/participants/edit/${row.id}`}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                {toggleParticipantActive.isLoading &&
                toggleParticipantActive.variables?.participantId === row.id ? (
                  <SwitchBox>
                    <CircularProgress size={20} />
                  </SwitchBox>
                ) : (
                  <Tooltip title={row.active ? t("Disable") : t("Enable")}>
                    <Switch
                      checked={row.active}
                      onChange={(e) => {
                        handleToggleParticipantActive(row.id, e.target.checked);
                      }}
                      disabled={row.registrationStatus === "Rascunho"}
                    />
                  </Tooltip>
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}

export default function ParticipantList() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Participants")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Participants")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Participants")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              component={NavLink}
              to="/participants/edit"
              variant="contained"
              color="primary"
            >
              <AddIcon />
              {t("Add")}
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ParticipantTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
