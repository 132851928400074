import { SignedUrlDto } from "../types/dtos/utils.dto";
import axiosInstance from "../utils/axios";

export async function generateUploadSignedUrl(body: SignedUrlDto) {
  const { data } = await axiosInstance.post(
    "/utils/upload-get-signed-url",
    body
  );
  return data;
}

export async function generateDownloadSignedUrl(body: SignedUrlDto) {
  const { data } = await axiosInstance.post(
    "/utils/download-get-signed-url",
    body
  );
  return data;
}

export async function generateDeleteSignedUrl(body: SignedUrlDto) {
  const { data } = await axiosInstance.post(
    "/utils/delete-get-signed-url",
    body
  );
  return data;
}
