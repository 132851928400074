export function validateCNPJ(cnpj: string | number) {
  if (!cnpj) return false;

  if (typeof cnpj === "string") {
    if (
      !/^\d{14}$/.test(cnpj) &&
      !/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj)
    ) {
      return false;
    }
  } else if (typeof cnpj !== "number") return false;

  const match = cnpj.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 14) return false;

  if (numbers.every((char) => char === numbers[0])) return false;

  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);

  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  const digit1 = calc(13);
  return digit1 === digits[1];
}

export function formatCNPJ(cnpj: string | number) {
  let value = cnpj.toString();

  if (/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)) return value;

  if (/^\d{14}$/.test(value)) {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return null;
}

export function validateCPF(cpf: string | number) {
  if (!cpf) return false;

  if (typeof cpf === "string") {
    if (!/^\d{11}$/.test(cpf) && !/^\d{3}.\d{3}.\d{3}-\d{2}$/.test(cpf)) {
      return false;
    }
  } else if (typeof cpf !== "number") return false;

  const match = cpf.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 11) return false;

  if (numbers.every((char) => char === numbers[0])) return false;

  const base = numbers.slice(0, 9);
  const digits = numbers.slice(9);

  const calc = (n: number, i: number, x: number) => n * (x - i);

  const sum = (r: number, n: number) => r + n;

  const digit = (n: number) => {
    const remainder = n % numbers.length;
    return remainder < 2 ? 0 : numbers.length - remainder;
  };

  const calc0 = base
    .map((n, i) => calc(n, i, numbers.length - 1))
    .reduce(sum, 0);
  const digit0 = digit(calc0);

  if (digit0 !== digits[0]) return false;

  const calc1 = base
    .concat(digit0)
    .map((n, i) => calc(n, i, numbers.length))
    .reduce(sum, 0);
  const digit1 = digit(calc1);

  return digit1 === digits[1];
}

export function formatCPF(cpf: string | number) {
  let value = cpf.toString();

  if (/^\d{3}.\d{3}.\d{3}-\d{2}$/.test(value)) return value;

  if (/^\d{11}$/.test(value)) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return null;
}
