import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";

import { formatCPF, validateCPF } from "../../utils/string";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import {
  activateAdministrator,
  createAdministrator,
  deactivateAdministrator,
  editAdministrator,
  getAdministrator,
  getAdministrators,
} from "../../services/administrators";
import { FormTextField } from "../../components/formFields/FormTextField";
import { ApiError } from "../../types/error";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const SwitchBox = styled(Box)`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 58px;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  vertical-align: middle;
`;

const TradeNameCell = styled.div`
  display: flex;
  align-items: center;
`;

const ModalActions = styled(Box)`
  display: flex;
  gap: 8px;
  padding: 16px;
`;

type Inputs = {
  fullName: string;
  email: string;
  phoneNumber: string;
  cellphoneNumber: string;
  cpf: string;
};

const inputsDefaultValues: Inputs = {
  fullName: "",
  email: "",
  phoneNumber: "",
  cellphoneNumber: "",
  cpf: "",
};

interface EditAdministratorModalContentProps {
  onClose: () => void;
  adminId: string;
}
function EditAdministratorModalContent({
  onClose,
  adminId,
}: EditAdministratorModalContentProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  // const { data: systems } = useQuery(
  //   ["/participant-users/systems"],
  //   getParticipantUserSystems,
  //   {
  //     initialData: [],
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const { data: roles } = useQuery(
  //   ["/participant-users/roles"],
  //   getParticipantUserRoles,
  //   {
  //     initialData: [],
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const {
    data: participantData,
    isError,
    isFetching,
  } = useQuery(
    ["/participant-user", adminId],
    () => getAdministrator(adminId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data !== null) {
          reset(data);
        } else {
          reset();
        }
      },
    }
  );

  const submitAddAdministrator = useMutation<any, unknown, Inputs>(
    (data) => createAdministrator(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/platform-admin"]);

        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        onClose();
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const submitEditAdministrator = useMutation<any, unknown, Inputs>(
    (data) => editAdministrator(adminId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/platform-admin"]);

        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        onClose();
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      if (adminId) {
        submitEditAdministrator.mutate(data);
      } else {
        submitAddAdministrator.mutate(data);
      }
    },
    [adminId, submitAddAdministrator, submitEditAdministrator]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isFetching || participantData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t("Administrator Data")}
        </Typography>

        <FormTextField
          control={control}
          name={"fullName"}
          rules={{
            required: {
              value: true,
              message: t("This field is required"),
            },
          }}
          label={t("Full Name")}
          variant="outlined"
          fullWidth
          my={2}
        />

        <FormTextField
          control={control}
          name={"email"}
          rules={{
            required: {
              value: true,
              message: t("This field is required"),
            },
          }}
          label={t("Email")}
          variant="outlined"
          fullWidth
          my={2}
          type="email"
          disabled={adminId !== ""}
        />

        {/* <Grid container spacing={[0, 6]}>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormCheckbox
                control={control}
                name={"alwaysSign"}
                label={t("Always Sign")}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={9} xl={10}>
            <FormTextField
              control={control}
              name={"position"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Position")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid> */}

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"phoneNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                pattern: {
                  value: /^\(\d{2}\) \d{4}-\d{4}$/,
                  message: t("The data entered is invalid", {
                    data: t("Telephone Number"),
                  }),
                },
              }}
              InputMaskProps={{ mask: "(99) 9999-9999" }}
              label={t("Telephone Number")}
              variant="outlined"
              fullWidth
              my={2}
              type="tel"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"cellphoneNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                pattern: {
                  value: /^\(\d{2}\) \d{5}-\d{4}$/,
                  message: t("The data entered is invalid", {
                    data: t("Cellphone Number"),
                  }),
                },
              }}
              InputMaskProps={{ mask: "(99) 99999-9999" }}
              label={t("Cellphone Number")}
              variant="outlined"
              fullWidth
              my={2}
              type="tel"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"cpf"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                validate: (value) =>
                  validateCPF(String(value)) ||
                  t("The data entered is invalid", {
                    data: t("CPF"),
                  }),
              }}
              InputMaskProps={{ mask: "999.999.999-99" }}
              label={t("CPF")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 0, 6]}>
          {/* <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"cpf"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                validate: (value) =>
                  validateCPF(String(value)) ||
                  t("The data entered is invalid", {
                    data: t("CPF"),
                  }),
              }}
              InputMaskProps={{ mask: "999.999.999-99" }}
              label={t("CPF")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"nationality"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Nationality")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"birthDate"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Birth Date")}
              variant="outlined"
              fullWidth
              my={2}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid> */}
        </Grid>

        {/* <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name={"documentTypeId"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data ?? "")}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={documentTypes.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = documentTypes.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Document Type")}
                      error={!!errors.documentTypeId}
                      helperText={errors.documentTypeId?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"documentNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Document Number")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"dateOfIssue"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Date of Issue")}
              variant="outlined"
              fullWidth
              my={2}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name={"issuingAgency"}
              label={t("Issuing Agency")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"federatedUnit"}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data ?? "")}
                  onInputChange={(e, data) => field.onChange(data)}
                  freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={UFs}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Federated Unit")}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid> */}
      </CardContent>

      {/* <CardContent>
        <Typography variant="h6" gutterBottom>
          {t("User Permissions")}
        </Typography>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"allowedSystems"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data)}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={systems.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = systems.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("System")}
                      error={!!errors.allowedSystems}
                      helperText={errors.allowedSystems?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"roleId"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data)}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={roles.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = roles.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Role")}
                      error={!!errors.roleId}
                      helperText={errors.roleId?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent> */}

      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          // mt={3}
          type="submit"
          // disabled={submitAddParticiapantUser.isLoading}
        >
          {t("Save")}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          // mt={3}
          type="button"
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
      </ModalActions>
    </form>
  );
}

interface AdministratorsTableProps {
  onClickEditButton: (id: string) => void;
}
function AdministratorsTable({ onClickEditButton }: AdministratorsTableProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    data: administratorsData,
    isLoading,
    isError,
  } = useQuery(["/platform-admin"], getAdministrators);

  const toggleAdministratorActive = useMutation<
    any,
    unknown,
    { adminId: number }
  >(activateAdministrator, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/platform-admin"]);
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const toggleAdministratorInactive = useMutation<
    any,
    unknown,
    { adminId: number }
  >(deactivateAdministrator, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/platform-admin"]);
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const handleToggleParticipantActive = React.useCallback(
    (adminId: number, value: boolean) => {
      if (value) {
        toggleAdministratorActive.mutate({ adminId });
      } else {
        toggleAdministratorInactive.mutate({ adminId });
      }
    },
    [toggleAdministratorActive, toggleAdministratorInactive]
  );

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      { id: "name", alignment: "left", label: t("Name") },
      { id: "cpf", alignment: "left", label: t("CPF") },
      { id: "email", alignment: "left", label: t("Email") },
      // { id: "className", alignment: "left", label: t("Class") },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || administratorsData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <EnhancedTable
      title={t("Administrators")}
      data={administratorsData}
      headCells={headCells}
      // toolbarActionButton={
      //   <Button
      //     variant="contained"
      //     color="primary"
      //     onClick={() => onClickEditButton("")}
      //   >
      //     <AddIcon />
      //     {t("Add")}
      //   </Button>
      // }
      keyExtractor={(row) => String(row.id)}
      renderRow={(row) => {
        return (
          <TableRow hover tabIndex={-1}>
            <TableCell component="th" scope="row">
              <TradeNameCell>{row.fullName}</TradeNameCell>
            </TableCell>
            <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
              {formatCPF(row.cpf) ?? "-"}
            </TableCell>
            <TableCell align="left">{row.email}</TableCell>
            {/* <TableCell align="left">
              {classNames.find((item) => item.id === row.classId)?.name ??
                row.classId}
            </TableCell> */}
            <TableCell padding="none" align="right">
              <Box mr={2} my={2} whiteSpace="nowrap">
                <Tooltip title={t("Edit")}>
                  <IconButton
                    aria-label={t("Edit")}
                    onClick={() => onClickEditButton(String(row.id))}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                {toggleAdministratorActive.isLoading &&
                toggleAdministratorActive.variables?.adminId === row.id ? (
                  <SwitchBox>
                    <CircularProgress size={20} />
                  </SwitchBox>
                ) : (
                  <Tooltip title={row.active ? t("Disable") : t("Enable")}>
                    <Switch
                      checked={row.active}
                      onChange={(e) => {
                        handleToggleParticipantActive(row.id, e.target.checked);
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}

export default function AdministratorsList() {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = React.useState<string | null>(null);
  const handleOpen = (id: string) => setOpenModal(id);
  const handleClose = () => setOpenModal(null);

  return (
    <React.Fragment>
      <Helmet title={t("Administrators")} />

      <Modal
        open={openModal !== null}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            padding: 4,
            overflowY: "auto",
          }}
        >
          <EditAdministratorModalContent
            onClose={handleClose}
            adminId={openModal ?? ""}
          />
        </Paper>
      </Modal>

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Administrators")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Administrators")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              onClick={() => handleOpen("")}
              variant="contained"
              color="primary"
              // disabled //TODO
            >
              <AddIcon />
              {t("Add")}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdministratorsTable onClickEditButton={handleOpen} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
