import React from "react";
import styled from "@emotion/styled";
import {
  useForm,
  SubmitHandler,
  Controller,
  SubmitErrorHandler,
} from "react-hook-form";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Typography,
  TextField as MuiTextField,
  Button as MuiButton,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { validateCNPJ } from "../../utils/string";
import {
  createParticipant,
  editParticipant,
  getParticipantClasses,
} from "../../services/participants";
import {
  fetchBanksData,
  fetchCEPData,
  fetchCities,
  fetchUFs,
  initialCepData,
} from "../../services/brazil";
import { ParticipantRegistrationData } from "../../types/entities/participants";
import { FormTextField } from "../../components/formFields/FormTextField";
import { ApiError } from "../../types/error";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Button = styled(MuiButton)(spacing);

const inputsDefaultValues: ParticipantRegistrationData = {
  classId: null,
  corporateName: "",
  tradeName: "",
  cnpj: "",
  stateRegistration: "",
  municipalRegistration: "",
  site: "",
  email: "",
  companyPhone: "",
  companyCell: "",

  registrationStepsFinished: [],

  cep: "",
  addressLine: "",
  addressNumber: "",
  complement: "",
  district: "",
  city: "",
  state: "",

  bankCode: "",
  bankAgency: "",
  bankAccount: "",
};

interface RegistrationDataCardProps {
  participantId: string | undefined;
  participantData: ParticipantRegistrationData | null;
}
export default function RegistrationDataCard({
  participantId,
  participantData,
}: RegistrationDataCardProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    clearErrors,
    reset,
  } = useForm<ParticipantRegistrationData>({
    defaultValues: inputsDefaultValues,
  });

  const [selectedState, setSelectedState] = React.useState("");

  const { data: classNames } = useQuery(
    ["/participants/classes"],
    getParticipantClasses,
    {
      initialData: [],
    }
  );

  const { data: UFs } = useQuery(["brazilUFs"], fetchUFs, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: cityList } = useQuery(
    ["brazilCities", selectedState],
    () => fetchCities(selectedState),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const [selectedCep, setSelectedCep] = React.useState("initial");
  const [cepIsValid, setCepIsValid] = React.useState(true);

  const { data: cepData, isLoading: isLoadingCepData } = useQuery(
    ["cep", selectedCep],
    () => fetchCEPData(selectedCep),
    {
      initialData: initialCepData,
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (selectedCep === "initial") return;

        if (data?.valid) {
          setCepIsValid(true);
          setValue("addressLine", data.addressLine);
          setValue("district", data.district);
          setValue("city", data.city);
          setValue("state", data.state);
        } else {
          setCepIsValid(false);
          setValue("addressLine", "");
          setValue("district", "");
          setValue("city", "");
          setValue("state", "");
        }
      },
      onError: () => {
        setValue("addressLine", "");
        setValue("district", "");
        setValue("city", "");
        setValue("state", "");
      },
    }
  );

  React.useEffect(() => {
    if (participantData) {
      // setSelectedCep(participantData.cep);
      // setSelectedState(participantData.state);
      reset(participantData);
    }
  }, [participantData, reset]);

  const submitAddRegistrationData = useMutation<
    { participantId: string },
    unknown,
    ParticipantRegistrationData
  >(createParticipant, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["/participants"]);

      if (data.participantId) {
        navigate(`/participants/edit/${data.participantId}`, { replace: true });
      } else {
        navigate(`/participants`);
      }

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const submitEditRegistrationData = useMutation<
    void,
    unknown,
    ParticipantRegistrationData
  >((data) => editParticipant(data, participantId ?? ""), {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants"]);
      queryClient.invalidateQueries(["/participants", participantId]);

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const onSubmit = React.useCallback<
    SubmitHandler<ParticipantRegistrationData>
  >(
    async (data) => {
      if (!participantId) {
        submitAddRegistrationData.mutate(data);
      } else {
        submitEditRegistrationData.mutate(data);
      }
    },
    [participantId, submitAddRegistrationData, submitEditRegistrationData]
  );

  const onInvalidSubmit = React.useCallback<
    SubmitErrorHandler<ParticipantRegistrationData>
  >(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  return (
    <Card mb={6}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Company Data")}
          </Typography>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={8} md={6}>
              <Controller
                control={control}
                name={"classId"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => field.onChange(data)}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={classNames.map((item) => item.id)}
                    getOptionLabel={(option) => {
                      const label = classNames.find(
                        (item) => item.id === option
                      )?.name;
                      return label ? t(label) : String(option);
                    }}
                    sx={{ my: 2 }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={t("Participant Class")}
                        error={!!errors.classId}
                        helperText={errors.classId?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <FormTextField
            control={control}
            name={"corporateName"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            label={t("Corporate Name")}
            variant="outlined"
            fullWidth
            my={2}
          />

          <Grid container spacing={[0, 0, 6]}>
            <Grid item xs={12} md={8}>
              <FormTextField
                control={control}
                name={"tradeName"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Trade Name")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"cnpj"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  validate: (value) =>
                    validateCNPJ(String(value)) ||
                    t("The data entered is invalid", {
                      data: t("CNPJ"),
                    }),
                }}
                InputMaskProps={{ mask: "99.999.999/9999-99" }}
                label={t("CNPJ")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                control={control}
                name={"stateRegistration"}
                label={t("State Registration")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormTextField
                control={control}
                name={"municipalRegistration"}
                label={t("Municipal Registration")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
          </Grid>

          <FormTextField
            control={control}
            name={"site"}
            label={t("Website")}
            variant="outlined"
            fullWidth
            my={2}
          />

          <FormTextField
            control={control}
            name={"email"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            label={t("Email")}
            variant="outlined"
            fullWidth
            my={2}
            type="email"
          />

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                control={control}
                name={"companyPhone"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  pattern: {
                    value: /^\(\d{2}\) \d{4}-\d{4}$/,
                    message: t("The data entered is invalid", {
                      data: t("Telephone Number"),
                    }),
                  },
                }}
                InputMaskProps={{ mask: "(99) 9999-9999" }}
                label={t("Telephone Number")}
                variant="outlined"
                fullWidth
                my={2}
                type="tel"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormTextField
                control={control}
                name={"companyCell"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  pattern: {
                    value: /^\(\d{2}\) \d{5}-\d{4}$/,
                    message: t("The data entered is invalid", {
                      data: t("Cellphone Number"),
                    }),
                  },
                }}
                InputMaskProps={{ mask: "(99) 99999-9999" }}
                label={t("Cellphone Number")}
                variant="outlined"
                fullWidth
                my={2}
                type="tel"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Address")}
          </Typography>

          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                control={control}
                name={"cep"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  pattern: {
                    value: /^\d{2}\.?\d{3}-?\d{3}$/,
                    message: t("The data entered is invalid", {
                      data: t("Postal Code"),
                    }),
                  },
                  validate: () => cepIsValid,
                  onChange: () => {
                    clearErrors([
                      "addressLine",
                      "addressNumber",
                      "complement",
                      "district",
                      "city",
                      "state",
                    ]);
                  },
                  onBlur: (e) => {
                    setSelectedCep(e.target.value);
                  },
                }}
                InputMaskProps={{ mask: "99999-999" }}
                label={t("Postal Code")}
                variant="outlined"
                fullWidth
                my={2}
                InputProps={{
                  endAdornment: isLoadingCepData ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={9}>
              <FormTextField
                control={control}
                name={"addressLine"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Address")}
                variant="outlined"
                fullWidth
                my={2}
                disabled={
                  isLoadingCepData || !cepData.cep || !!cepData.addressLine
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormTextField
                control={control}
                name={"addressNumber"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Address Number")}
                variant="outlined"
                fullWidth
                my={2}
                disabled={isLoadingCepData || !cepData.cep}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 0, 6]}>
            <Grid item xs={12} md={8}>
              <FormTextField
                control={control}
                name={"complement"}
                label={t("Complement")}
                variant="outlined"
                fullWidth
                my={2}
                disabled={isLoadingCepData || !cepData.cep}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"district"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("District")}
                variant="outlined"
                fullWidth
                my={2}
                disabled={
                  isLoadingCepData || !cepData.cep || !!cepData.district
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={"state"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  onChange: (e) => {
                    setValue("city", "");
                    setSelectedState(e.target.value);
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => field.onChange(data ?? "")}
                    onInputChange={(e, data) => field.onChange(data)}
                    disabled={
                      isLoadingCepData || !cepData.cep || !!cepData.state
                    }
                    freeSolo
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={UFs}
                    sx={{ my: 2 }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={t("State")}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={"city"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => field.onChange((data ?? "") as any)}
                    onInputChange={(e, data) => field.onChange(data)}
                    disabled={
                      isLoadingCepData || !cepData.cep || !!cepData.city
                    }
                    freeSolo
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={cityList}
                    sx={{ my: 2 }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={t("City")}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Bank info")}
          </Typography>

          <Grid container spacing={6}>
            <Grid item xs={12} sm={4} md={2}>
              <FormTextField
                control={control}
                name={"bankCode"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  validate: async (value) => {
                    const bankData = await fetchBanksData(
                      (value as string) || ""
                    );
                    return bankData === null ? "Código inválido" : true;
                  },
                }}
                label={t("BankCode")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormTextField
                control={control}
                name={"bankAgency"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Agency")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormTextField
                control={control}
                name={"bankAccount"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Account")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={Object.keys(dirtyFields).length === 0}
          >
            {t("Save")}
          </Button>
        </CardContent>
      </form>
    </Card>
  );
}
