import React from "react";
import { Navigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Loading() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet title={`${t("Loading")}...`} />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        <CircularProgress />
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t("Loading")}...
      </Typography>
    </Wrapper>
  );
}

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
