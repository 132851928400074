import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  useForm,
  SubmitHandler,
  Controller,
  SubmitErrorHandler,
} from "react-hook-form";
import {
  Alert as MuiAlert,
  Card as MuiCard,
  TextField as MuiTextField,
  Button as MuiButton,
  CardContent,
  Grid,
  Typography,
  Autocomplete,
  TableRow,
  TableCell,
  Box,
  Tooltip,
  Switch,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { formatCPF, validateCPF } from "../../utils/string";
import { useSnackbar } from "notistack";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  createParticipantLegalRepresentant,
  getOneParticipantLegalRepresentant,
  getParticipantLegalRepresentants,
  getParticipantRepresentativesDocumentTypes,
  updateActiveParticipantLegal,
  updateParticipantLegal,
} from "../../services/participant-legal-representants";
import { ParticipantLegalRepresentantDto } from "../../types/dtos/participant-legal-representants.dto";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { fetchUFs } from "../../services/brazil";
import { FormTextField } from "../../components/formFields/FormTextField";
import { FormCheckbox } from "../../components/formFields/FormCheckbox";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Button = styled(MuiButton)(spacing);

const Alert = styled(MuiAlert)(spacing);

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  fullName: string;
  email: string;
  alwaysSign: boolean;
  position: string;
  telephoneNumber: string;
  cellphoneNumber: string;
  cpf: string;
  nationality: string;
  birthDate: string;
  documentTypeId: number | null;
  documentNumber: string;
  dateOfIssue: string;
  issuingAgency: string;
  federatedUnit: string | null;
}

const inputsDefaultValues: Inputs = {
  fullName: "",
  email: "",
  alwaysSign: false,
  position: "",
  telephoneNumber: "",
  cellphoneNumber: "",
  cpf: "",
  nationality: "",
  birthDate: "",
  documentTypeId: null,
  documentNumber: "",
  dateOfIssue: "",
  issuingAgency: "",
  federatedUnit: null,
};

interface LegalRepresentativesCardProps {
  participantId: string;
  isParticipantActive: boolean;
}
export default function LegalRepresentativesCard({
  participantId,
  isParticipantActive,
}: LegalRepresentativesCardProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<Inputs>({ defaultValues: inputsDefaultValues });

  const resetForm = React.useCallback(() => {
    reset();
    setIsUpdate(false);
  }, [reset]);

  const { data: UFs } = useQuery(["brazilUFs"], fetchUFs, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: documentTypes } = useQuery(
    ["/participant-legal-representatives/representativeDocuments"],
    getParticipantRepresentativesDocumentTypes,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const [isUpdate, setIsUpdate] = useState(false);
  const [legalRepresentativeId, setLegalRepresentativeId] = useState<
    string | number | null
  >(null);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    async (data) => {
      try {
        const participantLegalRepresentantDto: ParticipantLegalRepresentantDto =
          {
            representativeFullName: data.fullName,
            representativeEmail: data.email,
            representativeAlwaysSign: data.alwaysSign,
            representativeJobPosition: data.position,
            representativePhone: data.telephoneNumber,
            representativeCellphone: data.cellphoneNumber,
            representativeCpf: data.cpf,
            representativeCitizenship: data.nationality,
            representativeBirthDate: data.birthDate,
            representativeDocumentTypeId: data.documentTypeId,
            representativeDocumentNumber: data.documentNumber,
            representativeDocumentIssueDate: data.dateOfIssue,
            representativeDocumentDispatchingEntity: data.issuingAgency,
            representativeDocumentUf: data.federatedUnit,
          };
        if (!isUpdate) {
          await createParticipantLegalRepresentant(
            participantId as string,
            participantLegalRepresentantDto
          );
        } else {
          await updateParticipantLegal(
            legalRepresentativeId as string,
            participantLegalRepresentantDto
          );
        }
        resetForm();
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        queryClient.invalidateQueries(["/participants"]);

        queryClient.invalidateQueries([
          "/participant-legal-representatives",
          participantId,
        ]);
      } catch (error: any) {
        enqueueSnackbar(t(error.error), {
          variant: "error",
        });
      }
    },
    [
      isUpdate,
      resetForm,
      enqueueSnackbar,
      t,
      queryClient,
      participantId,
      legalRepresentativeId,
    ]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  const onEdit = async (legalRepresentantId: number | string | null) => {
    const legalRepresentant = await getOneParticipantLegalRepresentant(
      legalRepresentantId as string
    );
    setValue("fullName", legalRepresentant.representativeFullName);
    setValue("alwaysSign", legalRepresentant.representativeAlwaysSign);
    setValue(
      "birthDate",
      legalRepresentant.representativeBirthDate.split("T")[0]
    );
    setValue("cellphoneNumber", legalRepresentant.representativeCellphone);
    setValue("telephoneNumber", legalRepresentant.representativePhone);
    setValue("email", legalRepresentant.representativeEmail);
    setValue("position", legalRepresentant.representativeJobPosition);
    setValue("cpf", legalRepresentant.representativeCpf);
    setValue("documentTypeId", legalRepresentant.representativeDocumentTypeId);
    setValue("documentNumber", legalRepresentant.representativeDocumentNumber);
    setValue(
      "dateOfIssue",
      legalRepresentant.representativeDocumentIssueDate.split("T")[0]
    );
    setValue(
      "issuingAgency",
      legalRepresentant.representativeDocumentDispatchingEntity
    );
    setValue("federatedUnit", legalRepresentant.representativeDocumentUf);
    setValue("nationality", legalRepresentant.representativeCitizenship);
    setLegalRepresentativeId(legalRepresentantId);
    setIsUpdate(true);
  };

  const onCancel = () => {
    resetForm();
  };

  const {
    data: legalRepresentativesData,
    isLoading,
    isError,
  } = useQuery(["/participant-legal-representatives", participantId], () =>
    getParticipantLegalRepresentants(participantId)
  );

  const handleToggleRowEnabled = React.useCallback(
    async (id: number, active: boolean) => {
      await updateActiveParticipantLegal(id);

      queryClient.invalidateQueries([
        "/participant-legal-representatives",
        participantId,
      ]);
    },
    [participantId, queryClient]
  );

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      { id: "fullName", alignment: "left", label: t("Name") },
      { id: "cpf", alignment: "left", label: t("CPF") },
      { id: "email", alignment: "left", label: t("Email") },
      { id: "actions", alignment: "right", label: t("Actions") },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || legalRepresentativesData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <Card mb={6}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Typography variant="h6" gutterBottom>
            {t("Legal Representative Data")}
          </Typography>

          <FormTextField
            control={control}
            name={"fullName"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            label={t("Full Name")}
            variant="outlined"
            fullWidth
            my={2}
          />

          <FormTextField
            control={control}
            name={"email"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            label={t("Email")}
            variant="outlined"
            fullWidth
            my={2}
            type="email"
          />

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormCheckbox
                  control={control}
                  name={"alwaysSign"}
                  label={t("Always Sign")}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} lg={9} xl={10}>
              <FormTextField
                control={control}
                name={"position"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Position")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                control={control}
                name={"telephoneNumber"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  pattern: {
                    value: /^\(\d{2}\) \d{4}-\d{4}$/,
                    message: t("The data entered is invalid", {
                      data: t("Telephone Number"),
                    }),
                  },
                }}
                InputMaskProps={{ mask: "(99) 9999-9999" }}
                label={t("Telephone Number")}
                variant="outlined"
                fullWidth
                my={2}
                type="tel"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                control={control}
                name={"cellphoneNumber"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  pattern: {
                    value: /^\(\d{2}\) \d{5}-\d{4}$/,
                    message: t("The data entered is invalid", {
                      data: t("Cellphone Number"),
                    }),
                  },
                }}
                InputMaskProps={{ mask: "(99) 99999-9999" }}
                label={t("Cellphone Number")}
                variant="outlined"
                fullWidth
                my={2}
                type="tel"
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 0, 6]}>
            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"cpf"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  validate: (value) =>
                    validateCPF(String(value)) ||
                    t("The data entered is invalid", {
                      data: t("CPF"),
                    }),
                }}
                InputMaskProps={{ mask: "999.999.999-99" }}
                label={t("CPF")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"nationality"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Nationality")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"birthDate"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Birth Date")}
                variant="outlined"
                fullWidth
                my={2}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 0, 6]}>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name={"documentTypeId"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => {
                      field.onChange((data ?? "") as any);
                    }}
                    // onInputChange={(e, data) => {
                    //   field.onChange(data);
                    // }}
                    // freeSolo
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={documentTypes.map((item) => item.id)}
                    getOptionLabel={(option) => {
                      const label = documentTypes.find(
                        (item) => item.id === option
                      )?.name;
                      return label ? t(label) : String(option);
                    }}
                    sx={{ my: 2 }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={t("Document Type")}
                        error={!!errors.documentTypeId}
                        helperText={errors.documentTypeId?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"documentNumber"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Document Number")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormTextField
                control={control}
                name={"dateOfIssue"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Date of Issue")}
                variant="outlined"
                fullWidth
                my={2}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={[0, 0, 6]}>
            <Grid item xs={12} md={6}>
              <FormTextField
                control={control}
                name={"issuingAgency"}
                label={t("Issuing Agency")}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"federatedUnit"}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => field.onChange((data ?? "") as any)}
                    onInputChange={(e, data) => field.onChange(data)}
                    freeSolo
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={UFs}
                    sx={{ my: 2 }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={t("Federated Unit")}
                        helperText=""
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" mt={3} type="submit">
            {t("Save")}
          </Button>
          {isUpdate && (
            <Button
              variant="outlined"
              mt={3}
              ml={3}
              type="button"
              onClick={onCancel}
            >
              {t("Cancel")}
            </Button>
          )}
        </form>
      </CardContent>

      <CardContent>
        <EnhancedTable
          title={t("List of Legal Representatives")}
          data={legalRepresentativesData}
          headCells={headCells}
          keyExtractor={(row) => String(row.id)}
          renderRow={(row) => {
            return (
              <TableRow hover tabIndex={-1}>
                <TableCell component="th" scope="row">
                  {row.representativeFullName}
                </TableCell>

                <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                  {formatCPF(row.representativeCpf) ?? "-"}
                </TableCell>
                <TableCell align="left">{row.representativeEmail}</TableCell>
                <TableCell padding="none" align="right">
                  <Box mr={2} my={2} whiteSpace="nowrap">
                    <Tooltip title={t("Edit")}>
                      <IconButton
                        aria-label={t("Edit")}
                        onClick={() => onEdit(row.id)}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={row.active ? t("Disable") : t("Enable")}>
                      <Switch
                        disabled={!isParticipantActive}
                        checked={row.active}
                        onChange={(e, checked) =>
                          handleToggleRowEnabled(row.id, checked)
                        }
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            );
          }}
        />
      </CardContent>
    </Card>
  );
}
