import React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import styled from "@emotion/styled";
import {
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import { spacing } from "@mui/system";

const FormControlLabel = styled(MuiFormControlLabel)(spacing);

export function FormCheckbox<TFieldValues extends FieldValues = FieldValues>(
  props: UseControllerProps<TFieldValues> &
    Omit<React.ComponentProps<typeof FormControlLabel>, "control">
) {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController(props);

  return (
    <FormControlLabel
      {...props}
      onChange={onChange as any}
      onBlur={onBlur}
      name={name}
      checked={value}
      inputRef={ref}
      control={<Checkbox />}
      label={props.label}
    />
  );
}
