import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import {
  FirebaseAuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
} from "../types/auth";
import { firebaseConfig } from "../config";
import axiosInstance from "../utils/axios";

const INITIALIZE = "INITIALIZE";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseAuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [profile, setProfile] = useState<
    firebase.firestore.DocumentData | undefined
  >();
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();

  const signOut = useCallback(async () => {
    await firebase.auth().signOut();
    queryClient.resetQueries();
  }, [queryClient]);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          // const docRef = firebase.firestore().collection("users").doc(user.uid);
          // docRef
          //   .get()
          //   .then((doc) => {
          //     if (doc.exists) {
          //       setProfile(doc.data());
          //     }
          //   })
          //   .catch((error) => {
          //     console.error(error);
          //   });

          const { claims } = await user.getIdTokenResult();
          const systems = claims.systems as Array<string> | undefined;

          if (
            systems?.find(
              (system) => system === process.env.REACT_APP_SYSTEM_NAME
            )
          ) {
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: {
                  ...user,
                  systems,
                },
              },
            });
          } else {
            signOut();
            enqueueSnackbar(
              t("User does not have permission to access this system."),
              {
                variant: "error",
              }
            );
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }

        axiosInstance.interceptors.request.use(
          async (config) => {
            if (user) {
              config.headers = {
                ...config.headers,
                "token-id": await user.getIdToken(),
                Authorization: `Bearer ${await user.getIdToken()}`,
              };
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      }),
    [dispatch, enqueueSnackbar, signOut, t]
  );

  const signIn = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection("users")
          .doc(res.user?.uid)
          .set({
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          });
      });

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          avatar: auth.avatar || profile?.avatar,
          displayName: auth.displayName || profile?.displayName,
          role: "user",
          systems: auth.systems,
        },
        signIn,
        signUp,
        signInWithGoogle,
        signInWithFaceBook,
        signInWithTwitter,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
