import { SidebarItemsType } from "../../types/sidebar";

import { Briefcase, Users } from "react-feather";
import { TFunction } from "react-i18next";

const getAppSection = (t: TFunction) =>
  [
    {
      href: "/participants",
      icon: Briefcase,
      title: t("Participants"),
    },
    {
      href: "/administrators",
      icon: Users,
      title: t("Administrators"),
    },
  ] as SidebarItemsType[];

const getNavItems = (t: TFunction) => [
  {
    title: "",
    pages: getAppSection(t),
  },
];

export default getNavItems;
