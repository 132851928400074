import axios from "axios";

export async function fetchUFs() {
  const { data } = await axios.get<Array<{ nome: string; sigla: string }>>(
    "https://brasilapi.com.br/api/ibge/uf/v1"
  );

  const ufs = data.map((item) => item.sigla).sort();

  return ufs;
}

export async function fetchCities(uf: string) {
  if (!uf) return [];

  const { data } = await axios.get<Array<{ nome: string }>>(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
  );

  const cities = data.map((city) => city.nome);

  return cities;
}

export const initialCepData = {
  cep: "",
  valid: false,
  addressLine: "",
  district: "",
  city: "",
  state: "",
};

export async function fetchCEPData(cep: string) {
  const cepValue = cep.replace(/\D/g, "");

  if (cepValue === "" || !/^[0-9]{8}$/.test(cepValue)) {
    return initialCepData;
  }

  const { data } = await axios.get(
    `https://viacep.com.br/ws/${cepValue}/json/`
  );

  if (data.erro) {
    return { ...initialCepData, cep: cepValue, valid: false };
  }

  return {
    cep: cepValue,
    addressLine: data.logradouro ?? "",
    district: data.bairro ?? "",
    city: data.localidade ?? "",
    state: data.uf,
    valid: true,
  };
}

export async function fetchBanksData(code: string) {
  if (code === "") {
    return null;
  }

  try {
    const { data } = await axios.get(
      `https://brasilapi.com.br/api/banks/v1/${code}`
    );
    return data;
  } catch (e) {
    return null;
  }
}
