import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Paper = styled(MuiPaper)(spacing);

export function descendingComparator(
  a: Record<string, any>,
  b: Record<string, any>,
  orderBy: string
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: Record<string, any>, b: Record<string, any>) =>
        descendingComparator(a, b, orderBy)
    : (a: Record<string, any>, b: Record<string, any>) =>
        -descendingComparator(a, b, orderBy);
}

export function stableSort<T extends Record<string, any> = Record<string, any>>(
  array: Array<T>,
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el: T, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

export type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
  disableSort?: boolean;
};

export type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};
export const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
}) => {
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface EnhancedTableToolbarProps {
  title: string;
  toolbarActionButton?: JSX.Element;
}
export const EnhancedTableToolbar = ({
  title,
  toolbarActionButton,
}: EnhancedTableToolbarProps) => {
  return (
    <Toolbar>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        container
        spacing={10}
      >
        <Grid item>
          <ToolbarTitle>
            <Typography variant="h6" id={`table-${title}`}>
              {title}
            </Typography>
          </ToolbarTitle>
        </Grid>

        <Grid item>{toolbarActionButton}</Grid>
      </Grid>
    </Toolbar>
  );
};

interface EnhancedTableProps<
  T extends Record<string, any> = Record<string, any>
> {
  data: Array<T>;
  title: string;
  headCells: HeadCell[];
  renderRow: (row: T, index: number) => JSX.Element;
  keyExtractor: (row: T, index: number) => string;
  toolbarActionButton?: JSX.Element;
}
export function EnhancedTable<
  T extends Record<string, any> = Record<string, any>
>({
  data,
  title,
  headCells,
  renderRow,
  keyExtractor,
  toolbarActionButton,
}: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = React.useState("tradeName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          title={title}
          toolbarActionButton={toolbarActionButton}
        />
        <TableContainer>
          <Table
            aria-labelledby={`table-${title}`}
            size={"medium"}
            aria-label={title}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <React.Fragment key={keyExtractor(row, index)}>
                    {renderRow(row, index)}
                  </React.Fragment>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
