import React from "react";
import styled from "@emotion/styled";
import useTheme from "../../hooks/useTheme";
import { THEMES } from "../../constants";
import { ReactComponent as LogoLight } from "../../vendor/logo-plug1-light-1.svg";
import { ReactComponent as LogoDark } from "../../vendor/logo-plug1-dark.svg";

export const BrandDark = styled(LogoDark)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 220px;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const BrandLight = styled(LogoLight)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 220px;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export function StyledLogo() {
  const { theme } = useTheme();

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <BrandDark /> : <BrandLight />}
    </React.Fragment>
  );
}
