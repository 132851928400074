import React from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Tab,
  Box,
  CircularProgress,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { spacing } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import RegistrationDataCard from "./RegistrationDataCard";
import DocumentsCard from "./DocumentsCard";
import LegalRepresentativesCard from "./LegalRepresentativesCard";
import UserMaintenanceCard from "./UserMaintenanceCard";
import { getParticipant } from "../../services/participants";
import OperatingLimitsCards from "./OperatingLimitsCards";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export default function ParticipantForm() {
  const { t } = useTranslation();
  const { id: participantId } = useParams();

  const [selectedTab, setSelectedTab] = React.useState("1");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const {
    data: participantData,
    isError,
    isFetching,
  } = useQuery(
    ["/participants", participantId],
    () => getParticipant(participantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const currentStage = participantData?.registrationStepsFinished.length ?? 0;

  const pageTitle =
    participantId === undefined
      ? t("Add Participant")
      : `${t("Edit Participant")}${
          participantData ? ` - ${participantData.tradeName}` : ""
        }`;

  const isParticipantActive = participantData?.active ?? false;
  const isParticipantDraft = participantData?.registrationStatus === "Rascunho";

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isFetching || participantData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <React.Fragment>
      <Helmet title={pageTitle} />

      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Home")}
        </Link>
        <Link component={NavLink} to="/participants">
          {t("Participants")}
        </Link>
        <Typography>{pageTitle}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChangeTab} aria-label={pageTitle}>
                    <Tab label={t("Registration Data")} value="1" />
                    <Tab
                      label={t("Documents")}
                      value="2"
                      disabled={currentStage < 1}
                    />
                    {/* <Tab
                      label={t("Operating Limits")}
                      value="3"
                      disabled={currentStage < 2}
                    /> */}
                    <Tab
                      label={t("Legal Representatives")}
                      value="3"
                      disabled={currentStage < 2}
                    />
                    <Tab
                      label={t("User Maintenance")}
                      value="4"
                      disabled={currentStage < 3}
                    />
                    <Tab
                      label={t("Operation Limits")}
                      value="5"
                      disabled={currentStage < 4}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <RegistrationDataCard
                    participantId={participantId}
                    participantData={participantData}
                  />
                </TabPanel>

                {/* The following steps need a participantId */}
                {participantId && participantData && (
                  <>
                    <TabPanel value="2">
                      <DocumentsCard />
                    </TabPanel>

                    <TabPanel value="3">
                      <LegalRepresentativesCard
                        participantId={participantId}
                        isParticipantActive={isParticipantActive}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <UserMaintenanceCard
                        participantId={participantId}
                        isParticipantActive={isParticipantActive}
                        isParticipantDraft={isParticipantDraft}
                        isRegistrationFinished={currentStage >= 4}
                      />
                    </TabPanel>
                    <TabPanel value="5">
                      <OperatingLimitsCards
                        participantId={participantId}
                        participantData={participantData}
                      />
                    </TabPanel>
                  </>
                )}
              </TabContext>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
