import {
  ParticipantLegalRepresentantDto,
  ParticipantLegalRepresentative,
} from "../types/dtos/participant-legal-representants.dto";
import { ParticipantLegalRepresentant } from "../types/entities/participant-legal-representant";
import axiosInstance from "../utils/axios";

export async function getParticipantLegalRepresentants(
  participantId: string | number
) {
  const { data } = await axiosInstance.get<ParticipantLegalRepresentative[]>(
    `/participant-legal-representatives/${participantId}`
  );
  return data;
}

export async function getOneParticipantLegalRepresentant(
  legalRepresentantId: string | number
): Promise<ParticipantLegalRepresentant> {
  const { data } = await axiosInstance.get(
    `/participant-legal-representatives/find-one/${legalRepresentantId}`
  );
  return data;
}

export async function createParticipantLegalRepresentant(
  participantId: string | number,
  participantLegalRepresentantDto: ParticipantLegalRepresentantDto
) {
  const { data } = await axiosInstance.post(
    `/participant-legal-representatives/${participantId}`,
    participantLegalRepresentantDto
  );
  return data;
}

export async function updateParticipantLegal(
  legalRepresentantId: string | number,
  participantLegalRepresentantDto: ParticipantLegalRepresentantDto
) {
  const { data } = await axiosInstance.patch(
    `/participant-legal-representatives/${legalRepresentantId}`,
    participantLegalRepresentantDto
  );
  return data;
}

export async function updateActiveParticipantLegal(
  legalRepresentantId: string | number
) {
  const { data } = await axiosInstance.patch(
    `/participant-legal-representatives/active/${legalRepresentantId}`
  );
  return data;
}

export async function getParticipantRepresentativesDocumentTypes() {
  const { data } = await axiosInstance.get<
    Array<{ id: number; name: string; value: string }>
  >("/participant-legal-representatives/representativeDocuments");

  const documentTypes = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });

  return documentTypes;
}
