import axiosInstance from "../utils/axios";
import { sendPasswordResetEmail } from "./firebase";

export interface Administrator {
  id: number;
  fullName: string;
  email: string;
  phoneNumber: string;
  cellphoneNumber: string;
  cpf: string;
  active: boolean;
}

export async function getAdministrators() {
  const { data } = await axiosInstance.get<Array<Record<string, any>>>(
    "/platform-admin"
  );

  const participants = data.map<Administrator>((item) => ({
    id: item.id,
    fullName: item.fullName,
    email: item.email,
    phoneNumber: item.phoneNumber,
    cellphoneNumber: item.cellphoneNumber ?? "",
    cpf: item.cpf ?? "",
    active: item.active ?? false,
    // systems: item.systemAdmins,
    // roleId: item.roleId,
  }));
  return participants;
}

export async function getAdministrator(id: number | string) {
  if (id === "") return null;

  const { data } = await axiosInstance.get<Record<string, any>>(
    `/platform-admin/${id}`
  );

  const participants: Administrator = {
    id: data.id,
    fullName: data.fullName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    cellphoneNumber: data.cellphoneNumber ?? "",
    cpf: data.cpf ?? "",
    active: data.active ?? false,
    // systems: item.systemAdmins,
    // roleId: item.roleId,
  };
  return participants;
}

export async function createAdministrator(adminData: Record<string, any>) {
  const dto = {
    fullName: adminData.fullName,
    email: adminData.email,
    phoneNumber: adminData.phoneNumber,
    cellphoneNumber: adminData.cellphoneNumber,
    cpf: adminData.cpf,
  };
  const { data } = await axiosInstance.post<Record<string, any>>(
    "/platform-admin",
    dto
  );

  // sendPasswordResetEmail(adminData.email);

  return data;
}

export async function editAdministrator(
  id: number | string,
  adminData: Record<string, any>
) {
  const dto = {
    fullName: adminData.fullName,
    email: adminData.email,
    phoneNumber: adminData.phoneNumber,
    cellphoneNumber: adminData.cellphoneNumber,
    cpf: adminData.cpf,
  };
  const { data } = await axiosInstance.patch<Record<string, any>>(
    `/platform-admin/${id}`,
    dto
  );

  return data;
}

export async function deactivateAdministrator({
  adminId,
}: {
  adminId: string | number;
}) {
  const { data } = await axiosInstance.patch(
    `/platform-admin/inactive/${adminId}`
  );

  return data;
}

export async function activateAdministrator({
  adminId,
}: {
  adminId: string | number;
}) {
  const { data } = await axiosInstance.patch(
    `/platform-admin/active/${adminId}`
  );

  // const adminData = await getAdministrator(adminId);

  // if (adminData) {
  //   sendPasswordResetEmail(adminData.email);
  // }

  return data;
}
