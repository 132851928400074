import React from "react";
import styled from "@emotion/styled";
import {
  useForm,
  SubmitHandler,
  Controller,
  SubmitErrorHandler,
} from "react-hook-form";
import {
  Alert as MuiAlert,
  Card as MuiCard,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Button as MuiButton,
  CardContent,
  Grid,
  Typography,
  Autocomplete,
  TableRow,
  TableCell,
  Box,
  Tooltip,
  Switch,
  IconButton,
  Modal,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { formatCPF, validateCPF } from "../../utils/string";
import { useSnackbar } from "notistack";
import { Add, Edit as EditIcon } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  activateParticipant,
  createParticipantUser,
  editParticipantUser,
  getParticipantUser,
  getParticipantUserDocumentTypes,
  getParticipantUserRoles,
  getParticipantUsers,
  getParticipantUserSystems,
  getUserByEmail,
} from "../../services/participants";
import { ParticipantUserRegistrationData } from "../../types/entities/participants";
import axiosInstance from "../../utils/axios";
import { fetchUFs } from "../../services/brazil";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { FormTextField } from "../../components/formFields/FormTextField";
import { FormCheckbox } from "../../components/formFields/FormCheckbox";
import { ApiError } from "../../types/error";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Alert = styled(MuiAlert)(spacing);

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const ModalActions = styled(Box)`
  display: flex;
  gap: 8px;
  padding: 16px;
`;

const SwitchBox = styled(Box)`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 58px;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  vertical-align: middle;
`;

type Inputs = ParticipantUserRegistrationData;

const inputsDefaultValues: Inputs = {
  fullName: "",
  email: "",
  alwaysSign: false,
  position: "",
  telephoneNumber: "",
  cellphoneNumber: "",
  cpf: "",
  nationality: "",
  birthDate: "",
  documentTypeId: null,
  documentNumber: "",
  dateOfIssue: "",
  issuingAgency: "",
  socialNetwork: "",
  federatedUnit: null,

  allowedSystems: null,
  roleId: null,
};

interface EditUserModalContentProps {
  onClose: () => void;
  participantId: string;
  userId: string;
}
function EditUserModalContent({
  onClose,
  participantId,
  userId,
}: EditUserModalContentProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({ defaultValues: inputsDefaultValues });

  const { data: UFs } = useQuery(["brazilUFs"], fetchUFs, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: documentTypes } = useQuery(
    ["/participant-users/user-documents"],
    getParticipantUserDocumentTypes,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const { data: systems } = useQuery(
    ["/participant-users/systems"],
    getParticipantUserSystems,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const { data: roles } = useQuery(
    ["/participant-users/roles"],
    getParticipantUserRoles,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: participantData,
    isError,
    isFetching,
  } = useQuery(
    ["/participant-user/find-one", participantId, userId],
    () => getParticipantUser(participantId, userId),
    {
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (data !== null) {
          reset(data);
        }
      },
      onError: () => {},
    }
  );

  const submitAddParticiapantUser = useMutation<
    any,
    unknown,
    ParticipantUserRegistrationData
  >((data) => createParticipantUser(participantId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants", participantId]);
      queryClient.invalidateQueries(["/participant-users", participantId]);

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });

      onClose();
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const submitEditParticiapantUser = useMutation<
    any,
    unknown,
    ParticipantUserRegistrationData
  >((data) => editParticipantUser(participantId, userId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants", participantId]);
      queryClient.invalidateQueries(["/participant-users", participantId]);
      queryClient.invalidateQueries([
        "/participant-user",
        participantId,
        userId,
      ]);

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });

      onClose();
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      if (userId) {
        submitEditParticiapantUser.mutate(data);
      } else {
        submitAddParticiapantUser.mutate(data);
      }
    },
    [submitAddParticiapantUser, submitEditParticiapantUser, userId]
  );

  const [selectedEmail, setSelectedEmail] = React.useState("");

  useQuery(["/email", selectedEmail], () => getUserByEmail(selectedEmail), {
    initialData: null,
    refetchOnWindowFocus: false,
    onSettled: (data) => {
      if (selectedEmail === "") return;

      if (data) {
        reset({
          ...data,
          telephoneNumber: data.phoneNumber,
          position: data.jobRole,
          nationality: data.citizenship,
          documentTypeId: data.userDocuments.documentTypes.id,
          documentNumber: data.userDocuments.documentNumber,
          federatedUnit: data.userDocuments.documentUf,
          issuingAgency: data.userDocuments.documentDispatchingEntity,
          dateOfIssue: data.userDocuments.documentIssueDate.split("T")[0],
        });
      }
    },
    onError: () => {},
  });

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isFetching || participantData === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t("User Data")}
        </Typography>

        <FormTextField
          control={control}
          name={"fullName"}
          rules={{
            required: {
              value: true,
              message: t("This field is required"),
            },
          }}
          label={t("Full Name")}
          variant="outlined"
          fullWidth
          my={2}
        />

        <FormTextField
          control={control}
          name={"email"}
          rules={{
            required: {
              value: !Boolean(userId),
              message: t("This field is required"),
            },
            onBlur: (e) => {
              setSelectedEmail(e.target.value);
            },
          }}
          label={t("Email")}
          variant="outlined"
          fullWidth
          my={2}
          type="email"
          disabled={Boolean(userId)}
        />

        <Grid container spacing={[0, 6]}>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormCheckbox
                control={control}
                name={"alwaysSign"}
                label={t("Always Sign")}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={9} xl={10}>
            <FormTextField
              control={control}
              name={"position"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Position")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 6]}>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              control={control}
              name={"telephoneNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                pattern: {
                  value: /^\(\d{2}\) \d{4}-\d{4}$/,
                  message: t("The data entered is invalid", {
                    data: t("Telephone Number"),
                  }),
                },
              }}
              InputMaskProps={{ mask: "(99) 9999-9999" }}
              label={t("Telephone Number")}
              variant="outlined"
              fullWidth
              my={2}
              type="tel"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              control={control}
              name={"cellphoneNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                pattern: {
                  value: /^\(\d{2}\) \d{5}-\d{4}$/,
                  message: t("The data entered is invalid", {
                    data: t("Cellphone Number"),
                  }),
                },
              }}
              InputMaskProps={{ mask: "(99) 99999-9999" }}
              label={t("Cellphone Number")}
              variant="outlined"
              fullWidth
              my={2}
              type="tel"
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"cpf"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                validate: (value) =>
                  validateCPF(String(value)) ||
                  t("The data entered is invalid", {
                    data: t("CPF"),
                  }),
              }}
              InputMaskProps={{ mask: "999.999.999-99" }}
              label={t("CPF")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"nationality"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Nationality")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"birthDate"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Birth Date")}
              variant="outlined"
              fullWidth
              my={2}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name={"documentTypeId"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange((data ?? "") as any)}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={documentTypes.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = documentTypes.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Document Type")}
                      error={!!errors.documentTypeId}
                      helperText={errors.documentTypeId?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"documentNumber"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Document Number")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormTextField
              control={control}
              name={"dateOfIssue"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              label={t("Date of Issue")}
              variant="outlined"
              fullWidth
              my={2}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name={"issuingAgency"}
              label={t("Issuing Agency")}
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"federatedUnit"}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data ?? "")}
                  onInputChange={(e, data) => field.onChange(data)}
                  freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={UFs}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Federated Unit")}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name={"socialNetwork"}
              label={t("Social Network")}
              variant="outlined"
              fullWidth
              my={2}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                pattern: {
                  value: /^\(\d{2}\) \d{5}-\d{4}$/,
                  message: t("The data entered is invalid", {
                    data: t("Cellphone Number"),
                  }),
                },
              }}
              InputMaskProps={{ mask: "(99) 99999-9999" }}
              type="tel"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t("User Permissions")}
        </Typography>

        <Grid container spacing={[0, 0, 6]}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"allowedSystems"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data)}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={systems.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = systems.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("System")}
                      error={!!errors.allowedSystems}
                      helperText={errors.allowedSystems?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"roleId"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data)}
                  // onInputChange={(e, data) => field.onChange(data)}
                  // freeSolo
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={roles.map((item) => item.id)}
                  getOptionLabel={(option) => {
                    const label = roles.find(
                      (item) => item.id === option
                    )?.name;
                    return label ? t(label) : String(option);
                  }}
                  sx={{ my: 2 }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label={t("Role")}
                      error={!!errors.roleId}
                      helperText={errors.roleId?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>

      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          mt={3}
          type="submit"
          disabled={submitAddParticiapantUser.isLoading}
        >
          {t("Save")}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          mt={3}
          type="button"
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
      </ModalActions>
    </form>
  );
}

interface UserMaintenanceCardProps {
  participantId: string;
  isParticipantActive: boolean;
  isParticipantDraft: boolean;
  isRegistrationFinished: boolean;
}
export default function UserMaintenanceCard({
  participantId,
  isParticipantActive,
  isParticipantDraft,
  isRegistrationFinished,
}: UserMaintenanceCardProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: roles } = useQuery(
    ["/participant-users/roles"],
    getParticipantUserRoles,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery(["/participant-users", participantId], () =>
    getParticipantUsers(participantId)
  );

  const toggleParticipantUserActive = useMutation<
    any,
    unknown,
    { userId: number; participantId: number }
  >(
    ({ userId }) => {
      return axiosInstance.patch(
        `/participant-users/active/${userId}/${participantId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/participant-users", participantId]);

        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
      },
    }
  );

  const toggleParticipantUserInactive = useMutation<
    any,
    unknown,
    { userId: number; participantId: number }
  >(
    ({ userId }) => {
      return axiosInstance.patch(
        `/participant-users/inactive/${userId}/${participantId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/participant-users", participantId]);

        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const handleToggleParticipantUserActive = React.useCallback(
    (userId: number, value: boolean) => {
      if (value) {
        toggleParticipantUserActive.mutate({
          userId,
          participantId: Number(participantId),
        });
      } else {
        toggleParticipantUserInactive.mutate({
          userId,
          participantId: Number(participantId),
        });
      }
    },
    [toggleParticipantUserActive, toggleParticipantUserInactive]
  );

  const [openUser, setOpenUser] = React.useState<string | null>(null);
  const handleOpen = (userId: string) => setOpenUser(userId);
  const handleClose = () => setOpenUser(null);

  const toggleParticipantActive = useMutation<
    any,
    unknown,
    { participantId: string }
  >(activateParticipant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/participants"]);

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const handleToggleParticipantActive = React.useCallback(() => {
    toggleParticipantActive.mutate({ participantId });
  }, [participantId, toggleParticipantActive]);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      { id: "fullName", alignment: "left", label: t("Name") },
      { id: "cpf", alignment: "left", label: t("CPF") },
      { id: "email", alignment: "left", label: t("Email") },
      { id: "role", alignment: "left", label: t("Role") },
      { id: "actions", alignment: "right", label: t("Actions") },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || users === undefined) {
    return (
      <ErrorBox>
        <CircularProgress />
      </ErrorBox>
    );
  }

  return (
    <>
      <Modal
        open={openUser !== null}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            padding: 4,
            overflowY: "auto",
          }}
        >
          <EditUserModalContent
            onClose={handleClose}
            participantId={participantId}
            userId={openUser ?? ""}
          />
        </Paper>
      </Modal>

      <Card mb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable
              title={t("Participants")}
              data={users}
              headCells={headCells}
              toolbarActionButton={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpen("")}
                >
                  <Add />
                  {t("Add")}
                </Button>
              }
              keyExtractor={(row) => String(row.id)}
              renderRow={(row) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      {row.fullName}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {formatCPF(row.cpf) ?? "-"}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      {roles.find((item) => item.id === row.roleId)?.name ??
                        row.roleId}
                    </TableCell>
                    <TableCell padding="none" align="right">
                      <Box mr={2} my={2} whiteSpace="nowrap">
                        <Tooltip title={t("Edit")}>
                          <IconButton
                            aria-label="edit"
                            size="large"
                            onClick={() => handleOpen(String(row.id))}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        {toggleParticipantUserActive.isLoading &&
                        toggleParticipantUserActive.variables?.userId ===
                          row.id ? (
                          <SwitchBox>
                            <CircularProgress size={20} />
                          </SwitchBox>
                        ) : (
                          <Tooltip
                            title={row.active ? t("Disable") : t("Enable")}
                          >
                            <Switch
                              checked={row.active}
                              onChange={(e) => {
                                handleToggleParticipantUserActive(
                                  row.id,
                                  e.target.checked
                                );
                              }}
                              disabled={!isParticipantActive}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }}
            />
          </Grid>
        </Grid>

        <Box marginTop={4} display="inline-flex">
          {isParticipantActive ? (
            <Alert severity="success">
              {t("The participant is already active")}
            </Alert>
          ) : !isParticipantDraft ? (
            <Alert severity="warning">
              {t("The participant is not active")}
            </Alert>
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!isRegistrationFinished}
              onClick={handleToggleParticipantActive}
            >
              {t("Finish and Activate Participant")}
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
}
