import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Alert as MuiAlert, Button } from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormTextField } from "../formFields/FormTextField";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
interface Inputs {
  email: string;
  password: string;
  remember: boolean;
}

const inputsDefaultValues: Inputs = {
  email: "",
  password: "",
  remember: false,
};

function SignIn() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { signIn } = useAuth();

  const { control, handleSubmit, setError } = useForm({
    defaultValues: inputsDefaultValues,
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string | null>(null);

  const onSubmit = React.useCallback<SubmitHandler<any>>(
    async (data) => {
      setIsSubmitting(true);
      try {
        await signIn(data.email, data.password);
        navigate("/");
      } catch (error: any) {
        setSubmitError(t("Something went wrong."));
        switch (error.code) {
          case "auth/user-not-found":
            setError(
              "email",
              {
                type: "value",
                message: t("User does not exist or has been disabled."),
              },
              { shouldFocus: true }
            );
            break;
          case "auth/wrong-password":
            setError(
              "password",
              {
                type: "value",
                message: t("The password is invalid."),
              },
              { shouldFocus: true }
            );
            break;
        }
      }
      setIsSubmitting(false);
    },
    [navigate, setError, signIn, t]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {submitError && (
        <Alert mt={2} mb={3} severity="warning">
          {submitError}
        </Alert>
      )}

      <FormTextField
        control={control}
        name="email"
        rules={{
          required: { value: true, message: t("This field is required") },
          maxLength: {
            value: 255,
            message: t("The data entered is too large"),
          },
        }}
        label={t("Email")}
        type="email"
        fullWidth
        my={2}
      />
      <FormTextField
        control={control}
        name="password"
        rules={{
          required: { value: true, message: t("This field is required") },
          maxLength: {
            value: 255,
            message: t("The data entered is too large"),
          },
        }}
        label={t("Password")}
        type="password"
        fullWidth
        my={2}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        sx={{ marginTop: 8 }}
      >
        {t("Sign in")}
      </Button>
      <Button
        component={Link}
        to="/auth/reset-password"
        fullWidth
        color="primary"
      >
        {t("Forgot password")}
      </Button>
    </form>
  );
}

export default SignIn;
