import React from "react";
import styled from "@emotion/styled";

import {
  Box,
  Button as MuiButton,
  ButtonProps,
  IconButton,
  InputAdornment,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Clear,
  CloudDownload,
  CloudUpload as CloudUploadIcon,
  DeleteForever as DeleteForeverIcon,
  Done,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import {
  generateDownloadSignedUrl,
  generateUploadSignedUrl,
} from "../../services/utils";
import {
  createParticipantDocuments,
  getAllParticipantdocuments,
  updateParticipantDocuments,
} from "../../services/participant-documents";
import { ParticipantDocumentsDto } from "../../types/dtos/participant-documents.dto";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Button = styled(MuiButton)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const TextField = styled(MuiTextField)(spacing);

const CloudUpload = styled(CloudUploadIcon)(spacing);

const InfoOutlined = styled(InfoOutlinedIcon)(spacing);

const maxSizePerFile = 2 * 1024 * 1024;

type RowType = {
  id: string;
  documentName: string;
  file?: string;
  required?: boolean;
};

type HeadCell = {
  id: keyof RowType | "actions";
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const getHeadCells = (t: TFunction) =>
  [
    { id: "documentName", alignment: "left", label: t("Document Name") },
    {
      id: "file",
      alignment: "center",
      label: (
        <Tooltip
          title={
            t("Files type must be x and less than y", {
              x: ".pdf, .docx, .doc, .png, .jpg, .jpeg",
              y: `${maxSizePerFile / (1024 * 1024)} MB`,
            }) as string
          }
        >
          <Box display="inline-flex" alignItems="center">
            <>
              {t("File")}{" "}
              <InfoOutlined ml={1} color="action" fontSize="small" />
            </>
          </Box>
        </Tooltip>
      ),
    },
  ] as Array<HeadCell>;

const EnhancedTableHead: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {getHeadCells(t).map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = () => {
  const { t } = useTranslation();

  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          {t("Documents")}
        </Typography>
      </ToolbarTitle>
      <Spacer />
    </Toolbar>
  );
};

interface ButtonDropzoneProps extends DropzoneOptions {
  ButtonProps?: ButtonProps;
}
function DropzoneButton({ ButtonProps = {}, ...props }: ButtonDropzoneProps) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone(props);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        aria-label={t("Upload a File")}
        size="large"
        {...ButtonProps}
        // color="inherit"
      >
        {isDragActive ? (
          t("Drop the file here...")
        ) : (
          <>
            <CloudUpload mr={2} />
            {t("Upload a File")}
          </>
        )}
      </Button>
    </div>
  );
}

function EnhancedTable() {
  const initialData: Array<RowType> = [
    {
      id: "1",
      required: true,
      documentName:
        "Ato constitutivo, estatuto ou contrato social em vigor, atualizado e devidamente registrado nos órgãos competentes, acompanhados dos documentos de eleição de seus atuais administradores e representantes",
    },
    {
      id: "2",
      required: true,
      documentName:
        "Prova de inscrição no Cadastro Nacional de Pessoa Jurídica",
    },
    {
      id: "3",
      required: true,
      documentName:
        "Último balanço financeiro e DRE (Demonstrativo do Resultado do Exercício)",
    },
    {
      id: "4",
      required: false,
      documentName: "",
    },
  ];

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { id: participantId } = useParams();
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [rows, setRows] = React.useState<RowType[]>([]);
  const [additionalInformation, setAdditionalInformation] = React.useState("");
  const [hasError, setHasError] = React.useState(false);

  const loadRows = React.useCallback(async () => {
    getAllParticipantdocuments(participantId as string).then((data) => {
      if (data) {
        const rows = data.files.map((f: any, index: number) => {
          return {
            id: f.path,
            documentName: f.description,
            required: index < 3,
            file: f.path,
          };
        });
        setIsUpdate(true);
        setRows(rows);
        setAdditionalInformation(data.additionalInformation);
      } else {
        setRows(initialData);
      }
    });
  }, [participantId]);

  React.useEffect(() => {
    loadRows();
  }, [loadRows]);

  const uploadFile = React.useCallback(
    async (f: any): Promise<string> => {
      const filePath = `${participantId}/${f.path}`;
      const signedUrl = await generateUploadSignedUrl({
        filePath,
      });
      await fetch(signedUrl, {
        method: "PUT",
        body: f,
        headers: {
          "Content-Type": f.type,
        },
      });
      return filePath;
    },
    [participantId]
  );

  const onDrop = React.useCallback(
    (index: number, acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        switch (rejectedFiles[0].errors[0].code) {
          case "file-invalid-type":
            enqueueSnackbar(
              t("File type must be x", {
                x: ".pdf, .docx, .doc, .png, .jpg, .jpeg",
              }),
              {
                variant: "error",
              }
            );
            break;
          case "file-too-large":
            enqueueSnackbar(
              `${t("File is larger than x", {
                x: maxSizePerFile / (1024 * 1024),
              })} MB`,
              {
                variant: "error",
              }
            );
            break;
          default:
            enqueueSnackbar(t("The file is not accepted"), {
              variant: "error",
            });
        }
      }

      if (acceptedFiles[0]) {
        uploadFile(acceptedFiles[0]).then((fileKey) => {
          setRows((oldValue) => {
            const newValue = [...oldValue];
            newValue[index].file = fileKey;
            return newValue;
          });
        });
      }
    },
    [enqueueSnackbar, t, uploadFile]
  );

  const downloadFile = (filePath: string) => {
    generateDownloadSignedUrl({
      filePath,
    }).then((signedUrl: string) => {
      window.open(signedUrl);
    });
  };

  const onDeleteFile = React.useCallback((row: any, index: number) => {
    setRows((oldValue) => {
      if (!row.required) {
        const newValue = [...oldValue];
        newValue.splice(index, 1);
        return newValue;
      } else {
        const newValue = [...oldValue];
        newValue[index].file = undefined;
        return newValue;
      }
    });
  }, []);

  const handleChangeRowInput = React.useCallback(
    (index: number, value: string) => {
      setRows((oldValue) => {
        const newValue = [...oldValue];
        newValue[index].documentName = value;
        return newValue;
      });
    },
    []
  );

  React.useEffect(() => {
    if (rows.length === 0) return;

    const emptyRowIdx = rows.findIndex((row) => !row.documentName && !row.file);

    if (emptyRowIdx === -1) {
      // Add empty line
      setRows([
        ...rows,
        {
          id: (Number(rows[rows.length - 1].id) + 1).toString(),
          documentName: "",
        },
      ]);
    } else if (emptyRowIdx !== rows.length - 1) {
      // Delete empty line
      const newRows = [...rows];
      newRows.splice(emptyRowIdx, 1);
      setRows(newRows);
    }
  }, [rows]);

  const callApi = React.useCallback(
    async (body: ParticipantDocumentsDto) => {
      try {
        if (!isUpdate) {
          await createParticipantDocuments(participantId as string, body);
        } else {
          await updateParticipantDocuments(participantId as string, body);
        }
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        queryClient.invalidateQueries(["/participants"]);
        await loadRows();
      } catch (error: any) {
        enqueueSnackbar(t(error.error), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, isUpdate, loadRows, participantId, queryClient, t]
  );

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const rowsOk = rows.slice(0, -1);

      if (rowsOk.find((item) => !item.file || !item.documentName)) {
        setHasError(true);
        enqueueSnackbar(t("You must fill in all required fields"), {
          variant: "error",
        });
        return;
      }
      const body: ParticipantDocumentsDto = {
        files: rowsOk.map((r: any) => ({
          description: r.documentName,
          path: r.file as string,
        })),
        additionalInformation,
      };

      await callApi(body);
    },
    [additionalInformation, callApi, enqueueSnackbar, rows, t]
  );

  return (
    <div>
      <Paper>
        <form onSubmit={handleSubmit}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead />

              <TableBody>
                {rows.map((row, index, arr) => {
                  return (
                    <TableRow hover tabIndex={-1} key={`${row.id}-${index}`}>
                      <TableCell component="th" scope="row">
                        {row.required ? (
                          row.documentName
                        ) : (
                          <TextField
                            value={row.documentName}
                            onChange={(e) =>
                              handleChangeRowInput(index, e.target.value)
                            }
                            InputProps={{
                              endAdornment: row.documentName ? (
                                <InputAdornment position="end">
                                  <Tooltip title={t("Clear")}>
                                    <IconButton
                                      size="small"
                                      aria-label={t("Clear")}
                                      onClick={() =>
                                        handleChangeRowInput(index, "")
                                      }
                                    >
                                      <Clear />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ) : undefined,
                            }}
                            placeholder={t("Add")}
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={
                              index < arr.length - 1 &&
                              hasError &&
                              !row.documentName
                            }
                            helperText={
                              index < arr.length - 1 &&
                              hasError &&
                              !row.documentName
                                ? t("This field is required")
                                : ""
                            }
                          />
                        )}
                      </TableCell>

                      <TableCell padding="none" align="center">
                        <Box my={2} width={200} height={48}>
                          <Box
                            height={"100%"}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                          >
                            {row.file ? (
                              <>
                                <Box mr={2}>
                                  <Done />
                                </Box>
                                <Box
                                  textAlign="left"
                                  width="100%"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  lineHeight={1}
                                >
                                  <Tooltip title={row.file}>
                                    <Typography
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      lineHeight={1}
                                      width="100%"
                                    >
                                      {row.file}
                                      <br />
                                    </Typography>
                                  </Tooltip>
                                </Box>
                                <Box ml={2}>
                                  <Tooltip title={t("Download")}>
                                    <IconButton
                                      aria-label={t("Download")}
                                      size="small"
                                      onClick={() => {
                                        downloadFile(row.file as string);
                                      }}
                                    >
                                      <CloudDownload />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                <Box ml={2}>
                                  <Tooltip title={t("Delete")}>
                                    <IconButton
                                      aria-label={t("Delete")}
                                      size="small"
                                      onClick={() => {
                                        onDeleteFile(row, index);
                                      }}
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </>
                            ) : (
                              <DropzoneButton
                                onDrop={(acceptedFiles, rejectedFiles) =>
                                  onDrop(index, acceptedFiles, rejectedFiles)
                                }
                                accept={{
                                  "application/pdf": [],
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    [],
                                  "application/msword": [],
                                  "image/png": [],
                                  "image/jpeg": [],
                                }}
                                maxSize={maxSizePerFile}
                                ButtonProps={
                                  index < arr.length - 1 &&
                                  hasError &&
                                  !row.file
                                    ? {
                                        sx: (theme) => ({
                                          border: `1px solid ${theme.palette.error.main}`,
                                        }),
                                      }
                                    : {}
                                }
                              />
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TextField
            value={additionalInformation}
            onChange={(e) => setAdditionalInformation(e.target.value)}
            label={t("Additional Information")}
            variant="outlined"
            fullWidth
            my={8}
            multiline
            minRows={4}
          />

          <Button variant="contained" color="primary" mt={3} type="submit">
            {t("Save")}
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default function DocumentsCard() {
  return <EnhancedTable />;
}
